const entities = {
	RECEIVE_ENTITY_RECORDS: 'RECEIVE_ENTITY_RECORDS',
	RECEIVE_AND_REPLACE_ENTITY_RECORDS: 'RECEIVE_AND_REPLACE_ENTITY_RECORDS',
	RECEIVE_ENTITY: 'RECEIVE_ENTITY',
	REMOVE_ENTITY_BY_ID: 'REMOVE_ENTITY_BY_ID',
	REMOVE_DELETE_ENTITY_ID: 'REMOVE_DELETE_ENTITY_ID',
	REMOVE_TRASH_ENTITY_ID: 'REMOVE_TRASH_ENTITY_ID',
	RECEIVE_TRASH_ENTITY_ID: 'RECEIVE_TRASH_ENTITY_ID',
	RECEIVE_DELETE_ENTITY_ID: 'RECEIVE_DELETE_ENTITY_ID',
};
const relations = {
	REMOVE_DIRTY_RELATION_ADDITION: 'REMOVE_DIRTY_RELATION_ADDITION',
	REMOVE_DIRTY_RELATION_DELETION: 'REMOVE_DIRTY_RELATION_DELETION',
	RECEIVE_RELATED_ENTITY_IDS: 'RECEIVE_RELATED_ENTITY_IDS',
	REMOVE_RELATED_ENTITY_IDS: 'REMOVE_RELATED_ENTITY_IDS',
	REMOVE_RELATED_ENTITIES_FOR_ENTITY: 'REMOVE_RELATED_ENTITIES_FOR_ENTITY',
	RECEIVE_DIRTY_RELATION_ADDITION: 'RECEIVE_DIRTY_RELATION_ADDITION',
	RECEIVE_DIRTY_RELATION_DELETION: 'RECEIVE_DIRTY_RELATION_DELETION',
	RECEIVE_UPDATED_ENTITY_ID_FOR_RELATIONS:
		'RECEIVE_UPDATED_ENTITY_ID_FOR_RELATIONS',
};

export const ACTION_TYPES = { entities, relations };
